import { nbIotNoLocationDisplayName } from '../constants';

const hierachy = [
  // 'road',
  'city',
  'county',
  'state',
  'country',
];

export default function constructDisplayAddress(
  address = {},
  asObject = false,
  includeRoad = false,
) {
  const hierachyToUse = [...hierachy];
  if (includeRoad) {
    hierachyToUse.unshift('road');
  }
  for (let i = 0; i < hierachyToUse.length; i += 1) {
    const field1 = hierachyToUse[i];
    const value1 = address[field1];

    if (value1) {
      for (let j = i + 1; j < hierachyToUse.length; j += 1) {
        const field2 = hierachyToUse[j];
        const value2 = address[field2];

        if (value2 && value1 !== value2) {
          return asObject
            ? {
              name: value1.trim(),
              subTitle: value2.trim(),
            }
            : `${value1.trim()} (${value2.trim()})`;
        }
      }

      return asObject
        ? { name: value1.trim() }
        : value1.trim();
    }
  }

  return asObject
    ? { name: nbIotNoLocationDisplayName }
    : nbIotNoLocationDisplayName;
}
